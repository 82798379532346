import React from "react";
import logo from "../assets/logo.png";

const Loader = () => {
  return (
    <div className="main" id="loader-container">
      <div className="container">
        <img src={logo} alt="logo" />
        <div className="loading-indicator"></div>
        <h4 className="subtitle">JCC SPRTS</h4>
      </div>
    </div>
  );
};

export default Loader;

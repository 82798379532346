import React from "react";
import "./App.css";
import Loader from "./components/Loader";

const APP_DETAILS = {
  // Update these with your actual app details
  IOS_APP_STORE_ID: "6503223711",
  ANDROID_PACKAGE_NAME: "com.jcc.app",
  DEEP_LINK_URL: "https://app.jccsprts.com",
};

function App() {
  // React.useEffect(() => {
  //   const redirectToDownloadPage = setTimeout(() => {
  //     // Enter One Link here
  //     const link = "";
  //     window.location.href = link;

  //     return () => {
  //       clearTimeout(redirectToDownloadPage);
  //     };
  //   }, 3000);
  // }, []);

  React.useEffect(() => {
    let redirectTimeout;
    
    const handleRedirect = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const isIOS = /iphone|ipad|ipod/.test(userAgent);
      
      // Store URLs
      const storeUrl = isIOS 
        ? `https://apps.apple.com/app/id${APP_DETAILS.IOS_APP_STORE_ID}`
        : `https://play.google.com/store/apps/details?id=${APP_DETAILS.ANDROID_PACKAGE_NAME}`;
      
      // Try to open the app
      const openApp = () => {
        const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = APP_DETAILS.DEEP_LINK_URL;
          document.body.appendChild(iframe);
  
          // Fallback to store URL after timeout
          redirectTimeout = setTimeout(() => {
            window.location.replace(storeUrl);
          }, 2500);
      };

      // Set up visibility change detection
      document.addEventListener('visibilitychange', () => {
        if (document.hidden) {
          // User switched to the app, clear the store redirect
          clearTimeout(redirectTimeout);
        }
      });
      openApp();
    };

    handleRedirect();

    return () => {
      clearTimeout(redirectTimeout);
    };
  }, []);

  return <Loader />;
}

export default App;
